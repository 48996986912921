@charset "utf-8";
@import "./variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "swiper/scss";
@import "swiper/scss/navigation";

.slider-product-photo {
    .slide-product-photo {

        &:hover {
            img {
                opacity: 1;
            }
        }

        &.active {
            .slide-product-photo__image {
                border:#000 solid 3px;
            }
        }

        .slide-product-photo__image {
            border: #f8f8f8 solid 3px;
            border-radius: 2px;
            background-color: #d8d8d8;
            opacity: 0.7;
            height: inherit;
        }
    }
}

.slider-events-header {
    display: flex;
    justify-content: center;
    color: #ffffff;
    column-gap: 10px;
    align-items: center;

    @include media-breakpoint-up(sm) {
        column-gap: 20px;
    }

    @include media-breakpoint-up(md) {
        column-gap: 40px;
    }

    @include media-breakpoint-up(lg) {
        column-gap: 70px;
    }
}

.slider-events-header-link-wrap {
    flex-basis: 100%;

    @include media-breakpoint-up(lg) {
        flex-basis: content;
    }
}

.slider-events-navigation__btn {
    width: 26px;
    height: 25px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.slider-events-navigation__btn_prev {
    background: url("./../svg/icon-left-white-arrow-size-lg.svg") no-repeat;
}

.slider-events-navigation__btn_next {
    background: url("./../svg/icon-right-white-arrow-size-lg.svg") no-repeat;
}
