@charset "utf-8";

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";

.form-popup__form-group-checkboxes {
    margin-bottom: 20px;
}

.form-input-radio {
    display: flex;
    justify-content: space-between;
    width: 320px;
    column-gap: 20px;
}

.form-label-error {
    display: none;
}

.form-radio {
    position: relative;
}

.form__radio-label_red  {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &::after {
        content: " ";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("./../svg/icon-radio-unchecked.svg") no-repeat;
        margin-right: 5px;
    }
}

.form__radio-input_red {
    visibility: hidden;
    position: absolute;

    &:checked {

        & + .form__radio-label_red {

            &::after {
                background: url("./../svg/icon-radio-checked.svg") no-repeat;
            }
        }
    }
}
