@charset "utf-8";

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";

/* consult */
.bem-consult-container {
	display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.12);
    border: #e9e9e9 solid 1px;
    margin-bottom: 20px;
}

.bem-consult-container-left {
	display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 68px;
    background: #a50100;
    color: #fff;
    font-size: 26px;
}

.bem-consult-container-left-title {
	display: flex;
    align-items: center;
    justify-content: center;
    background: #a50100;
    font-size: 26px;
}

.bem-consult-container-left__consult-container-left-title {
	padding: 0 68px;
}

.bem-consult-container-left-title-link {
	color: #fff;
    text-decoration: none;
}

.bem-consult-container-left-title-link__text {
	font-family: 'Roboto';
    font-size: 30px;
    line-height: 34px;
    text-transform: uppercase;
}

.bem-consult-container-right {
	flex: 1 auto;
}

.bem-consult-container-right-top {
	display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #a50100;
    padding: 6px 20px;
    height: 60px;
    color: #FFF;
    font-size: 17px;
    line-height: 22px;
    font-family: 'Roboto';
}

.bem-consult-container-right-top-link {
    color: #fff;
    text-decoration: none;
}

.bem-consult-container-right-bottom {
    padding: 0 15px;
}

.bem-consult-detail-link-wrap {
    float: right;
    margin: 0;
    text-align: center;
}

.bem-button_black {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    padding: 6px 40px;
    background: #1e1f20;
}

.bem-price-list {
    min-height: inherit;
    font-size: 15px;
    line-height: 22px;
    margin: 12px 0 0 0;
    list-style: none;
    padding: 0;
}

.bem-price-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 20px;

    &::before {
        content: '';
        background-image: url("./../svg/icon-down-red-arrow.svg");
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;
    }
}

@media (min-width: 992px) {
	.bem-consult-container-right-bottom {
		padding: 0 10px;
	}
}

@media (min-width: 1200px) {
	.bem-consult-container-right-bottom {
		padding: 0 25px;
	}
}

.price-item-buttons {
    display: flex;
    flex-flow: column wrap;
    row-gap: 10px;
    align-items: center;
}

.bem-training-price__bem-rounded-check-list {
    margin: 15px 0 20px 0;

    @include media-breakpoint-up(lg) {
        min-height: 210px;
    }
}

.bem-training-promo {
    display: flex;
    flex-direction: column;
    max-width: 1190px;
}

.bem-training-promo-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: center;
    background-color: #a50100;
    color: #fff;
    font-family: "Roboto";
    padding: 7px 23px;
    text-align: center;
    text-decoration: none;

    @include media-breakpoint-up(b481) {
        justify-content: space-between;
    }

    &:hover,
    &:focus,
    &:visited {
        color: #fff;
        text-decoration: none;
    }
}

.bem-training-promo-header__author {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}

.bem-training-promo-header__price {
    display: flex;
    font-size: 14px;
}

.bem-training-promo-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.12);
    border-color: #e9e9e9;
    border-width: 1px;
    border-style: none solid solid solid;
    @include media-breakpoint-up(lg) {
        justify-content: inherit;
    }
}

.bem-training-promo-content-image {
    display: none;
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-grow: 0;
        justify-content: inherit;
    }
}

.bem-training-promo-content-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 18px 10px 18px 0;
}

.bem-training-promo-content-buttions {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    row-gap: 10px;
}

.bem-button-link {
    background: #1e1f20;
    color: #fff;
    display: block;
    font-size: 20px;
    line-height: 32px;
    padding: 6px 40px;
    text-decoration: none;
    text-transform: uppercase;
    
    &:hover,
    &:focus,
    &:visited {
        color: #fff;
        text-decoration: none;
    }
}

.bem-button-link__bg_red {
    background: #a50100;
}

.bem-button-link__font_lg {
    font-size: 24px;
}

.bem-rounded-check-list {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    list-style: none;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
}

.bem-training-promo-content-text__bem-rounded-check-list {
    margin: 0 0 18px 10px;
}

.bem-rounded-check-list__item {
    display: flex;
    align-items: baseline;
    column-gap: 7px;

    &::before {
        background-image: url("./../svg/icon-round-check-red.svg");
        background-repeat: no-repeat;
        content: "";
        width: 13px;
        height: 13px;
        flex: 0 0 13px;
    }
}
