$enable-smooth-scroll: false;
@charset "utf-8";
@import "./variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "./_icons.scss";
@import "./_slider.scss";
@import "./_reviews.scss";
@import "./_video-gallery.scss";
@import "./_training.scss";
@import "./_search.scss";
@import "./_lessons.scss";
@import "./_forms.scss";

body {
    font-family: 'Roboto-Light', Arial, Helvetica, sans-serif;
    line-height: 26px;
    font-size: 18px;
    color: #1d1e21;
}

/* bootrap */
/* .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(md) {
        width: 750px;
    }

    @include media-breakpoint-up(lg) {
        width: 970px;
    }

    @include media-breakpoint-up(lg) {
        width: 1170px;
    }
} */
/* /bootrap */

.home-section-bottom-text-wrap {
    display: flex;
    row-gap: 20px;
    flex-flow: column wrap;
    align-items: center;
}

.home-section-header {
    font-size: 24px;
    line-height: 32px;
    font-family: 'Roboto';
    text-align: center;
    color: #fff;
    margin-bottom: 0;

    @include media-breakpoint-up(b391) {
        font-size: 26px;
        line-height: 36px;
    }

    @include media-breakpoint-up(b481) {
        font-size: 32px;
        line-height: 40px;
    }

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 44px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 52px;
        line-height: 60px;
    }
}

.home-section-bottom-menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 0px;
    row-gap: 20px;
    list-style: none;
    font-size: 24px;
    line-height: 55px;
    text-transform: uppercase;
}

.home-section-bottom-menu-item {
    margin: 0 15px;
    min-width: 145px;
}

.home-section-bottom-menu-item__link {
    display: block;
    border: #b7b8be solid 1px;
    padding: 0 22px;
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
        background: url("./../images/menu-active.png");
        color: #fff;
    }
}

.home-section-bottom-text {
    padding-top: 25px;
    padding-bottom: 0;
    height: inherit;
    position: relative;
    background: url("./../images/navbar-bg.png");

    @include media-breakpoint-up(b391) {
        padding-top: 10px;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 30px 0 20px 0;
    }
}

.link-button {
    color: #fff;
    font-size: 20px;
    padding: 15px 0;
    text-align: center;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #c40302;
        text-decoration: none;
    }
}

.link-button_red {
    background: #a50100;
}

.link-button_size_md {
    font-size: 24px;
    padding: 15px 63px;
}

.link-button_text_upper {
    text-transform: uppercase;
}

.home-section-bottom-text-wrap__link-button_text_upper {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.mobile-panel-content {
    display: none;

    @include media-breakpoint-up(b320) {
        display: flex;
    }
}

.mobile-panel {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    &__social-link {
        margin: 0 3px;
    }
}

.training-forms-social {
    text-align: center;
    font-size: 22px;
    line-height: 46px;
    vertical-align: middle;

    &__text {
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 26px;
        }

        @media (max-width: 991px) {
            display: block;
        }
    }

    &__social-link {
        margin-left: 10px;
    }
}

.menu-social {
    line-height: 37px;
    vertical-align: middle;
    width: 220px;
    margin: 0 auto;

    &__social-link {
        margin: 0 15px 15px 0;
    }
}

.footer-right {
    .footer-right__footer-social {
        margin-bottom: 7px;
    }
}

.footer-social {
    line-height: 37px;
    vertical-align: middle;

    @media (max-width: 768px) {
        width: 220px;
        margin: 0 auto;
    }

    .footer-social__social-link {
        margin: 0 7px 10px;

        @include media-breakpoint-up(lg) {
            margin: 0 10px 0 0;
        }
    }
}

.contacts {
    &__contacts-social {
        margin-bottom: 20px;
    }
}

.contacts-social {
    font-size: 22px;
    line-height: 46px;
    vertical-align: middle;

    @media (max-width: 480px) {
        text-align: center;
    }

    &__social-link {
        margin: 0 5px 5px 5px;

        @include media-breakpoint-up(lg) {
            margin: 0 10px 0 0;
        }
    }
}

.price-inner__top {
    .price-inner__title,
    .price-inner__price {
        color: #a50100;
    }
}

.collapse-list {
	background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 20px;

    .collapse-list-panel + .collapse-list-panel {
        margin-top: 20px;
    }
}

.collapse-list-panel {
    border: none;
}

.collapse-list-heading {
    color: #333;
    border: none;
    border-radius: 0;
    background-color: #ccc;
    padding: 6px 10px;
    font-family: 'Roboto-Medium';
}

.collapse-list-heading-link {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    font-size: 18px;
    line-height: 26px;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
        color: #171717;
    }

    &.collapsed {

        .collapse-list-heading-link__text {

            &::after {
                content: '';
                display: inline-block;
                background-image: url("./../svg/icon-down-red-arrow.svg");
                background-repeat: no-repeat;
                width: 14px;
                height: 9px;
                margin-left: 7px;
                margin-top: -2px;
            }
        }
    }
}

.collapse-list-body {
    border: none;
    padding: 15px 0 0 0;
    font-size: 16px;
    line-height: 24px;
}

.search-wrap {
    display: inline-block;
}
.search {

    .search-form {
        display: flex;
    }

    .search-form__input {
        &,
        &:focus {
            border-top: #929292 solid 1px;
            border-left: #a4a4a4 solid 1px;
            border-right: #cecece solid 1px;
            border-bottom: #dbdbdb solid 1px;
            font-size: 16px;
            line-height: 24px;
            color: #000;
            padding: 3px 10px;
            outline: none;
            border-radius: 0;
            box-shadow: inset 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
            background: #FFF;
            font-family: 'Roboto-Light';
        }
    }

    .search-form__input_sm {
        height: 24px;
        width: 180px;
        border-right: none;

        &:focus {
            border-right: none;
        }
    }

    .search-form__submit {
        font-family: "Roboto-Light";
        padding: 2px 10px;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        background-color: #a50100;
        text-decoration: none;
        border-radius: 1px;
        border: none;
        display: inline-block;
        transition: all ease-out 0.2s;
        text-transform: uppercase;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
        
        @include media-breakpoint-up(md) {
            padding: 2px 30px;
        }
    }

    .search__search-form__submit {
        margin-left: 10px;
    }

    .search-form__submit-inline {
        position: relative;
        padding: 11px;
        background: #fff;
        color: white;
        border-top: #929292 solid 1px;
        border-right: #a4a4a4 solid 1px;
        border-bottom: #dbdbdb solid 1px;
        border-left: none;
        cursor: pointer;
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        
        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            background: url("./../svg/icon-search.svg") no-repeat;
        }
    }
    
    .search__search-result-hint {
        padding: 18px 0 14px;
    }

    .search-form__divider {
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to bottom,#929292, #dbdbdb) 1 100%;
    }
}

.search-wrap__search {
    margin-right: 20px;
}

.navbar-collapse__search {
    padding: 10px 10px 10px 0;
}

.navbar-toggle {

    .icon-bar {
        background-color: #fff;
        display: block;
        width: 28px;
        height: 3px;

        & + .icon-bar {
            margin-top: 7px;
        }
    }
}

.logo-text {
    color: #fff;
    float: left;
    font-size: 9px;
    line-height: 17px;
    margin: 10px 0 0 0;
    width: 150px;
    text-transform: uppercase;
    position: relative;

    @include media-breakpoint-up(sm) {
        font-size: 13px;
        line-height: 18px;
        margin: 7px 0 0 0;
        width: 200px;
        text-transform: none;
    }

    @include media-breakpoint-up(md) {
        margin: 5px 10px 0 0;
        width: 370px;
        line-height: 32px;
        font-size: 24px;
    }
}

.main-ordered-block {
    display: flex;
    flex-direction: column;
}

.school {
    padding: 20px 0 0 0;
    order: 10;
    
    @include media-breakpoint-up(md) {
        order: unset;
    }

    @include media-breakpoint-up(lg) {
        padding: 30px 0;
    }
}

.stage {
    background: #f9f9f9;
    padding: 10px 0 0 0;

    @include media-breakpoint-up(md) {
        padding-bottom: 45px;
    }
}

.forms-training {
    display: none;

    @include media-breakpoint-up(b481) {
        display: block;
        padding: 20px 0;
        background: #060606 url("./../images/forms-training.jpg") center no-repeat;
        color: #FFF;
    }

    @include media-breakpoint-up(md) {
        padding: 30px 0;
    }
}

.events-main {
    background: #af2e01 url("./../images/section5-bg.jpg") top center no-repeat;
    padding: 20px 0;
    color: #fff;
    font-size: 14px;
    line-height: 22px;

    @include media-breakpoint-up(lg) {
        padding: 30px 0 50px 0;
    }

    @include media-breakpoint-up(xl) {
        padding: 40px 0 90px 0;
    }
}

.programm-top {
    padding: 20px 0 0 0;
    background: #f9f9f9 url("./../images/programm-top.jpg") top center no-repeat;
    order: -10;

    @include media-breakpoint-up(md) {
        padding: 30px 0 0 0;
        order: unset;
    }
}
/* .navbar-toggle {
    display: block;
    position: relative;
    float: left;
    padding: 0;
    margin: 20px 20px 0 0;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 0;

    @include media-breakpoint-up(md) {
        display: none;
    }

    .icon-bar {
        background-color: #fff;
        border-radius: 0;
        display: block;
        width: 28px;
        height: 3px;
    }
} */
