@charset "utf-8";

.social-link {
    display: inline-block;
    width: 46px;
    height: 46px;
    vertical-align: middle;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    transition: none;

    &_telegram {
        background: url("./../svg/icon-social-telegram.svg") no-repeat;
    }

    &_whatsapp {
        background: url("./../svg/icon-social-whatsapp.svg") no-repeat;
    }

    &_email_black {
        background: url("./../svg/icon-social-email-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-email-black-hover.svg") no-repeat;
        }
    }

    &_email_white {
        background: url("./../svg/icon-social-email-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-email-white-hover.svg") no-repeat;
        }
    }

    &_vk_black {
        background: url("./../svg/icon-social-vk-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-vk-black-hover.svg") no-repeat;
        }
    }

    &_vk_white {
        background: url("./../svg/icon-social-vk-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-vk-white-hover.svg") no-repeat;
        }
    }

    &_youtube_black {
        background: url("./../svg/icon-social-youtube-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-youtube-black-hover.svg") no-repeat;
        }
    }

    &_youtube_white {
        background: url("./../svg/icon-social-youtube-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-youtube-white-hover.svg") no-repeat;
        }
    }

    &_instagram_black {
        background: url("./../svg/icon-social-instagram-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-instagram-black-hover.svg") no-repeat;
        }
    }

    &_instagram_white {
        background: url("./../svg/icon-social-instagram-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-instagram-white-hover.svg") no-repeat;
        }
    }

    &_telegram_black,
    &_telegram_channel_black {
        background: url("./../svg/icon-social-telegram-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-telegram-black-hover.svg") no-repeat;
        }
    }

    &_telegram_white,
    &_telegram_channel_white {
        background: url("./../svg/icon-social-telegram-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-telegram-white-hover.svg") no-repeat;
        }
    }

    &_bandlink_black {
        background: url("./../svg/icon-social-bandlink-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-bandlink-black-hover.svg") no-repeat;
        }
    }

    &_bandlink_white {
        background: url("./../svg/icon-social-bandlink-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-bandlink-white-hover.svg") no-repeat;
        }
    }

    &_yandex-zen_black {
        background: url("./../svg/icon-social-yandex-zen-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-yandex-zen-black-hover.svg") no-repeat;
        }
    }

    &_yandex-zen_white {
        background: url("./../svg/icon-social-yandex-zen-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-yandex-zen-white-hover.svg") no-repeat;
        }
    }

    &_facebook_black {
        background: url("./../svg/icon-social-facebook-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-facebook-black-hover.svg") no-repeat;
        }
    }

    &_facebook_white {
        background: url("./../svg/icon-social-facebook-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-facebook-white-hover.svg") no-repeat;
        }
    }

    &_soundcloud_black {
        background: url("./../svg/icon-social-soundcloud-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-soundcloud-black-hover.svg") no-repeat;
        }
    }

    &_soundcloud_white {
        background: url("./../svg/icon-social-soundcloud-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-soundcloud-white-hover.svg") no-repeat;
        }
    }

    &_twitter_black {
        background: url("./../svg/icon-social-twitter-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-twitter-black-hover.svg") no-repeat;
        }
    }

    &_twitter_white {
        background: url("./../svg/icon-social-twitter-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-twitter-white-hover.svg") no-repeat;
        }
    }

    &_google-plus_black {
        background: url("./../svg/icon-social-google-plus-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-google-plus-black-hover.svg") no-repeat;
        }
    }

    &_google-plus_white {
        background: url("./../svg/icon-social-google-plus-white.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-google-plus-white-hover.svg") no-repeat;
        }
    }

    &_whatsapp_black {
        background: url("./../svg/icon-social-whatsapp-black.svg") no-repeat;

        &:hover {
            background: url("./../svg/icon-social-whatsapp-black-hover.svg") no-repeat;
        }
    }

    &_size_sm {
        width: 37px;
        height: 37px;
    }

    &_size_xs {
        width: 30px;
        height: 30px;
    }
}
