@charset "utf-8";

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_utilities.scss";

.lessons-block {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(b481) {
        & {
            margin-bottom: inherit;
        }
    }
}

.lessons-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;

    @include media-breakpoint-up(b481) {
        border-bottom: #a3a3a3 solid 1px;
    }
}

.lessons-block__lessons-block-header {
    margin-bottom: 10px;

    @include media-breakpoint-up(b481) {
        margin-bottom: 30px;
    }
}

.lessons-search-categories-wrap {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    @include media-breakpoint-up(b481) {
        column-gap: 20px;
        flex-direction: row;
        flex-grow: 0;
    }
}

.lessons-block__title {
    background-color: #8e2120;
    border-color: #8e2120;
    border: #8e2120 solid 1px;
    color: #fff;
    flex-grow: 1;
    font-size: 20px;
    font-family: 'Roboto-Light', Arial, Helvetica, sans-serif;
    line-height: 28px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding: 7px 0 9px;
    position: relative;

    @include media-breakpoint-up(b481) {
        border: none;
        color: #1d1e21;
        border-color: inherit;
        background-color: inherit;
        flex-grow: inherit;
        font-size: 24px;
        font-family: 'Roboto-Medium';
        line-height: 26px;
        text-align: inherit;

        &::after {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            right: 0;
            bottom: -7px;
            height: 3px;
            background-color: #8e2120;
        }
    }
}

.lessons-category__link-all {
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Roboto-bold';
    font-size: 14px;
    line-height: 45px;
    padding: 0 33px;
    text-decoration: none;
    background-color: #8e2120;
    position: relative;

    &:hover,
    &:focus {
        color: #ffffff;
        background-color: #a50100;
    }

    &:hover {
        &::after {
            right: 11px;
        }
    }

    &::after {
        position: absolute;
        content: '';
        background-image: url("./../svg/icon-right-arrow-grey.svg");
        background-repeat: no-repeat;
        top: 19px;
        right: 15px;
        width: 5px;
        height: 7px;
        transition: all ease-out 0.1s;
    }

    @include media-breakpoint-up(b481) {
        display: block;
    }
}

.lessons-block__lessons-category__link-all {
    margin-top: 7px;
}

.lessons-category__link-all_article {
    padding-left: 60px;

    &::before {
        position: absolute;
        content: '';
        background-image: url("./../svg/icon-article.svg");
        background-repeat: no-repeat;
        left: 0;
        width: 45px;
        height: 45px;
    }
}

.lessons-category__link-all_video {
    padding-left: 60px;

    &::before {
        position: absolute;
        content: '';
        background-image: url("./../svg/icon-video.svg");
        background-repeat: no-repeat;
        left: 0;
        width: 45px;
        height: 45px;
    }
}

.lessons-block__lessons-category__link-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    @include media-breakpoint-up(b481) {
        display: none;
    }
}

.lessons-block-items {
    display: flex;
    flex-direction: column;
    margin: 0;
    row-gap: 20px;

    @include media-breakpoint-up(b481) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: inherit;
        column-gap: 10px;
    }

    @include media-breakpoint-up(md) {
        margin: 0 -5px;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 -14px;
    }
}

.lessons-item {
    display: flex;
    background-color: #ebebeb;
    vertical-align: top;
    align-items: center;

    @include media-breakpoint-up(b481) {
        flex-direction: column;
    }
}

.lessons-block-items__lessons-item {
    margin: 0;

    @include media-breakpoint-up(b481) {
        margin: 0 0 25px;
    }

    @include media-breakpoint-up(md) {
        margin: 0 5px 25px 5px;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 7px 25px 7px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0 14px 35px 14px;
    }
}

.lessons-item-image-wrap {
    flex: 0 0 40%;
    position: relative;
    height: fit-content;

    @include media-breakpoint-up(b481) {
        flex: inherit;
    }
}

.lessons-item-image-wrap_article {

    &::after {
        position: absolute;
        content: '';
        background-image: url("./../svg/icon-article-grey.svg");
        background-repeat: no-repeat;
        left: 0;
        bottom: 0;
        width: 45px;
        height: 45px;
    }
}

.lessons-item-image-wrap_video {

    &::after {
        position: absolute;
        content: '';
        background-image: url("./../svg/icon-video-grey.svg");
        background-repeat: no-repeat;
        left: 0;
        bottom: 0;
        width: 45px;
        height: 45px;
    }
}

.lessons-item__lessons-item-image-wrap {

    @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
    }
}

.lessons-item__name {
    font-family: 'Roboto-Medium';
    line-height: 26px;
    font-size: 18px;
    padding: 10px;
    overflow-x: hidden;
    
    @include media-breakpoint-up(b481) {
        line-height: 22px;
        font-size: 12px;
        padding: 0 5px 10px;
    }

    @include media-breakpoint-up(md) {
        line-height: 24px;
        font-size: 16px;
        padding: 0 10px 10px;
    }

    @include media-breakpoint-up(xl) {
        line-height: 26px;
        font-size: 18px;
    }
}
